<template>
  <div :class="['content', isTurnHappening ? 'animate-fire' : '']">
    <div class="bg"></div>

    <video :class="['fire']" autoplay muted loop>
      <source src="../../assets/fire-burning.mp4" type="video/mp4" />
    </video>
    <div v-if="isTurnHappening" class="burning-dcar">
      <h3><InlineLoading class="loading" /> Burning {{ dcauCost }} DCAR</h3>
    </div>
    <template v-if="!isTurnHappening">
      <div v-if="isSessionActive">
        <template v-if="hasStarted">
          <div class="free-mint" v-if="isFreeMintActive">
            <div class="black-box">
              <h2 class="timer">
                Freemint duration
                <CountdownTimerMint
                  :timeLeft="60"
                  :duration="freeMintRoundEndDuration"
                  @ended="endFreeMint"
                />
              </h2>
            </div>

            <div class="black-box">
              <h4>Free Mint</h4>
              <div class="grid two">
                <p class="available-free-mints">
                  Available Free Mints {{ availableFreeMints }}
                </p>
                <div class="burn-dcar-button">
                  <BlueButton
                    :disabled="
                      isMinting ||
                      !isSessionActive ||
                      isLoadingInitialData ||
                      dcauCost === 0
                    "
                    :isLoading="isMinting"
                    @click="freeMint"
                    size="large"
                    >Claim Free Mint</BlueButton
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="allow-list" v-if="isAllowListActive && !isFreeMintActive">
            <div class="black-box">
              <h2 class="timer">
                Allowlist Mint Duration
                <CountdownTimerMint
                  :timeLeft="60"
                  v-if="allowListRoundEndDuration != 0 && isAllowListActive"
                  :duration="allowListRoundEndDuration"
                  @ended="endAllowMint"
                />
              </h2>
            </div>
            <div class="black-box">
              <h4>Allowed List Mint</h4>
              <div class="grid">
                <div class="balances col">
                  <h5>Cost</h5>
                  <div class="dcau" id="dcau-balance">
                    <template v-if="!isFetchingDcarBalance">
                      {{
                        allowListMintQuantity == 0
                          ? dcauCost
                          : (
                              parseFloat(allowListMintQuantity) * dcauCost
                            ).toFixed(3)
                      }}
                    </template>
                    <template v-else>
                      <SkeletalLoading width="100" height="20" />
                    </template>
                  </div>
                  <!-- <div class="dcau" id="dcau-balance">
                    <template v-if="!isFetchingDcarBalance">
                      {{ dcauBalance }}
                    </template>
                    <template v-else>
                      <SkeletalLoading width="100" height="20" />
                    </template>
                  </div> -->
                </div>
                <div class="input-amount col">
                  <h5>Enter quantity to mint</h5>
                  <input
                    type="number"
                    class="mint-quantity"
                    name="dcar-amount"
                    step="1"
                    min="0"
                    :max="availableAllowMints"
                    @input="checkAllowanceAgain"
                    v-model="allowListMintQuantity"
                    :disabled="
                      isLoadingInitialData ||
                      isApprovingDCAU ||
                      !isSessionActive ||
                      isMinting
                    "
                  />
                  <p class="dcau" id="dcau-balance">
                    Balance:
                    <template v-if="!isFetchingDcarBalance">
                      {{ dcauBalance }} DCAU | Available Mints
                      {{ availableAllowMints }}
                    </template>
                    <template v-else>
                      <SkeletalLoading width="100" height="20" />
                    </template>
                  </p>
                </div>
                <div class="burn-dcar-button">
                  <BlueButton
                    v-if="isDCAUApproved"
                    :disabled="
                      isMinting ||
                      !isSessionActive ||
                      isLoadingInitialData ||
                      dcauCost === 0
                    "
                    :isLoading="isMinting"
                    @click="mintAllowList"
                    size="large"
                    >Mint</BlueButton
                  >
                  <BlueButton
                    v-else
                    :disabled="
                      isLoadingInitialData ||
                      isApprovingDCAU ||
                      !isSessionActive
                    "
                    @click="approveDCAU"
                    size="large"
                    :isLoading="isApprovingDCAU"
                    >Approve DCAU</BlueButton
                  >
                </div>
              </div>
            </div>
          </div>

          <template v-if="!isFreeMintActive && !isAllowListActive">
            <div class="black-box" v-if="quantityLeft == 0">
              <br />
              <h2>SOLD OUT!</h2>
              <br />
            </div>
            <div class="black-box" v-else>
              <h4>Public Mint</h4>
              <div class="grid">
                <div class="balances col">
                  <h5>Cost</h5>
                  <div class="dcau" id="dcau-balance">
                    <template v-if="!isFetchingDcarBalance">
                      {{
                        publicListMintQuantity == 0
                          ? dcauCost.toFixed(3)
                          : (publicListMintQuantity * dcauCost).toFixed(3)
                      }}
                    </template>
                    <template v-else>
                      <SkeletalLoading width="100" height="20" />
                    </template>
                  </div>
                </div>
                <div class="input-amount col">
                  <h5>Enter quantity to mint</h5>
                  <input
                    type="number"
                    class="mint-quantity"
                    name="dcar-amount"
                    step="1"
                    min="1"
                    :max="maxPerWallet"
                    @input="checkAllowanceAgain"
                    v-model="publicListMintQuantity"
                    :disabled="
                      isLoadingInitialData ||
                      isApprovingDCAU ||
                      !isSessionActive ||
                      isMinting
                    "
                  />
                  <p class="dcau" id="dcau-balance">
                    Balance:
                    <template v-if="!isFetchingDcarBalance">
                      {{ dcauBalance }} DCAU | Max {{ maxPerWallet }} per wallet
                    </template>
                    <template v-else>
                      <SkeletalLoading width="100" height="20" />
                    </template>
                  </p>
                </div>
                <div class="burn-dcar-button">
                  <BlueButton
                    v-if="isDCAUApproved"
                    :disabled="
                      isMinting ||
                      !isSessionActive ||
                      isLoadingInitialData ||
                      dcauCost === 0
                    "
                    :isLoading="isMinting"
                    @click="mintPublic"
                    size="large"
                    >Mint</BlueButton
                  >
                  <BlueButton
                    v-else
                    :disabled="
                      isLoadingInitialData ||
                      isApprovingDCAU ||
                      !isSessionActive
                    "
                    @click="approveDCAU"
                    size="large"
                    :isLoading="isApprovingDCAU"
                    >Approve DCAU</BlueButton
                  >
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else-if="durationToStart > 0">
          <div class="black-box">
            <h2 class="timer">
              Round Starts In
              <CountdownTimerMint
                @ended="startRound"
                :timeLeft="60"
                :duration="durationToStart"
              />
            </h2>
          </div>
        </template>
        <template v-else>
          <GenericLoading />
        </template>
      </div>
      <div v-else class="black-box">
        <br />
        <h2>Sign In To Get More Details</h2>
        <br />
      </div>
      <div class="black-box">
        <div class="progress-container">
          <p class="current">
            Quantity Minted: {{ quantityMinted
            }}<span v-if="isSessionActive && !isLoadingInitialData">
              | You Own: {{ yourTokenCount }}</span
            >
          </p>
          <div class="progress">
            <span :style="`width: ${totalProgress}%`"></span>
            <p class="start mark">0</p>
            <p class="end mark">{{ totalQuantity }}</p>
          </div>
        </div>
      </div>
    </template>
    <div class="video-container">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/57poTyTXx54"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div class="reward" v-if="showRewards">
    <div class="data">
      <h2>Congratulations!</h2>
      <h3 v-if="rewardType === 'free'">
        You claimed {{ mintedQuantity }} tokens!
      </h3>
      <h3 v-else-if="rewardType === 'allow'">
        You minted {{ mintedQuantity }} tokens!
      </h3>
      <h3 v-else-if="rewardType === 'public'">
        You minted {{ mintedQuantity }} tokens!
      </h3>
      <BlueButton
        :disabled="
          isMinting ||
          !isSessionActive ||
          isLoadingInitialData ||
          dcauCost === 0
        "
        :isLoading="isMinting"
        @click="closeReward"
        size="large"
        >Continue</BlueButton
      >
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Constants from "../../consts/constants";

// import GenericLoading from "../../components/LoadingComponents/GenericLoading.vue";
import SkeletalLoading from "../../components/LoadingComponents/SkeletalLoading.vue";
import GenericLoading from "../../components/LoadingComponents/GenericLoading.vue";
import CountdownTimerMint from "../../components/CountdownTimerMint.vue";

import { ethers } from "ethers";
import { parseError } from "../../utils/helpers";
import { useUser } from "../../composables/user";
import { usePrice } from "../../composables/price";
import { useSound } from "../../composables/sound";
import trumpetSound from "../../assets/sound/trumpet.mp3";

import InlineLoading from "../../components/LoadingComponents/InlineLoading.vue";

import {
  getDCGHeroesRedeemTokenContract,
  getDCAUContract,
} from "../../utils/getContract";
import BlueButton from "../../components/Buttons/BlueButton.vue";

export default {
  displayName: "MintGameBase",
  components: {
    GenericLoading,
    BlueButton,
    SkeletalLoading,
    InlineLoading,
    CountdownTimerMint,
  },
  setup() {
    // Composables
    const store = useStore();
    const router = useRouter();
    const { address, signer } = useUser();
    const { getDCAUBalance, dcauBalance } = usePrice();

    // Loading related
    const isLoading = ref(false);
    const isDCAUApproved = ref(false);
    const isLoadingInitialData = ref(true);
    const isFetchingDcarBalance = ref(true);
    const isApprovingDCAU = ref(false);
    const isMinting = ref(false);
    const zeroAddress = ethers.constants.AddressZero;
    const currentAllowance = ref(0);
    const freeMintRoundEndDuration = ref(0);
    const allowListRoundEndDuration = ref(0);
    const durationToStart = ref(0);
    // const roundStartTime = ref(null);
    const hasStarted = ref(false);
    const isFreeMintActive = ref(false);
    const isAllowListActive = ref(false);
    const yourTokenCount = ref(0);

    // Reactive data variables
    const isLoggedIn = ref(false);
    const dcauCost = ref(0);
    const currentRound = ref(0);
    const quantityLeft = ref(0);
    const totalQuantity = ref(250); // HARD CODING TOTAL QUANTITY
    const freeMintDuration = ref(0);
    const allowListDuration = ref(0);
    const startTime = ref(null);
    const maxPerWallet = ref(0);
    const availableFreeMints = ref(0);
    const allowListMintQuantity = ref(0);
    const availableAllowMints = ref(0);
    const publicListMintQuantity = ref(1);
    const showRewards = ref(false);
    const rewardType = ref("");
    const mintedQuantity = ref("");
    // const mintAmount = ref(0);

    // Contract reactive variables
    const DCGHeroesRedeemToken = ref(null);
    const DCAUContract = ref(null);

    const playTrumpetSound = useSound(trumpetSound, {
      volume: 1,
      interrupt: true,
    });

    onMounted(async () => {
      // preloading sound files for better experience
      const trumpetSoundFile = new Audio(trumpetSound);
      trumpetSoundFile.load();

      /**
       * Initialising contract instances if signer is available
       */
      if (isSessionActive.value) {
        await initialiseContractsAndGetData();
        subscribeToMint();
      } else {
        await getDataViaRPC();
      }
    });

    onUnmounted(() => {
      unWatchSessionActive();
    });

    /**
     * Fetch DATA using rpc provider instead of a signer
     */
    const getDataViaRPC = async () => {
      try {
        const rpcProvider = new ethers.providers.JsonRpcProvider(
          Constants.rpcUrl
        );
        // Initalising contract using rpc provider
        const contract = getDCGHeroesRedeemTokenContract(rpcProvider);

        const round = await contract.TOKEN_ROUND_COUNTER();
        currentRound.value = parseInt(ethers.utils.formatUnits(round, 0));
        const details = await contract.SALE_ROUNDS(currentRound.value);
        quantityLeft.value = ethers.utils.formatUnits(details.quantity, 0);
      } catch (error) {
        console.log(error);
      }
    };

    const endFreeMint = () => {
      isFreeMintActive.value = false;
      isAllowListActive.value = true;
    };
    const endAllowMint = () => {
      isFreeMintActive.value = false;
      isAllowListActive.value = false;
    };
    const startRound = async () => {
      hasStarted.value = true;
      isFreeMintActive.value = true;
      isAllowListActive.value = false;
      await refreshData();
    };
    const loadPage = (type) => {
      console.log(type);
    };
    const initialiseContractsAndGetData = async () => {
      isLoadingInitialData.value = true;
      if (isSessionActive.value) {
        // Initalising contract instances
        DCGHeroesRedeemToken.value = getDCGHeroesRedeemTokenContract(
          signer.value
        );
        DCAUContract.value = getDCAUContract(signer.value);

        // Checking if DCAR approved or not
        await checkIsDCAUApproved();

        await refreshData();

        // Loading leaderboard
      }
      isLoadingInitialData.value = false;
    };

    const closeReward = () => {
      showRewards.value = false;
      rewardType.value = "";
    };
    const refreshData = async () => {
      // Getting DCAR Balance
      isFetchingDcarBalance.value = true;
      await getDCAUBalance();
      isFetchingDcarBalance.value = false;

      const round = await DCGHeroesRedeemToken.value.TOKEN_ROUND_COUNTER();
      currentRound.value = parseInt(ethers.utils.formatUnits(round, 0));
      const details = await DCGHeroesRedeemToken.value.SALE_ROUNDS(
        currentRound.value
      );

      const tokenCount = await DCGHeroesRedeemToken.value.balanceOf(
        address.value
      );

      yourTokenCount.value = ethers.utils.formatUnits(tokenCount, 0);

      console.log(currentRound.value, address.value);
      const free = await DCGHeroesRedeemToken.value.SALE_ROUND_FREE_MINT(
        currentRound.value,
        address.value
      );
      console.log("free big number", free);
      console.log("FREE", ethers.utils.formatUnits(free, 0));
      availableFreeMints.value = ethers.utils.formatUnits(free, 0);

      const allowMints =
        await DCGHeroesRedeemToken.value.SALE_ROUND_ALLOWLISTED(
          currentRound.value,
          address.value
        );

      availableAllowMints.value = ethers.utils.formatUnits(allowMints, 0);

      dcauCost.value = ethers.utils.formatUnits(details.dcauCost, 18);
      quantityLeft.value = parseInt(
        ethers.utils.formatUnits(details.quantity, 0)
      );
      freeMintDuration.value = parseInt(
        ethers.utils.formatUnits(details.freeMintDuration, 0)
      );
      allowListDuration.value = parseInt(
        ethers.utils.formatUnits(details.allowListDuration, 0)
      );

      startTime.value = parseInt(
        ethers.utils.formatUnits(details.startTime, 0)
      );
      maxPerWallet.value = ethers.utils.formatUnits(details.maxPerWallet, 0);

      // Time calculations

      const currentTime = Math.floor(Date.now() / 1000);

      if (startTime.value > currentTime) {
        hasStarted.value = false;
        durationToStart.value = startTime.value - currentTime;
        console.log("DURATION TO START", durationToStart.value);
      } else {
        hasStarted.value = true;
        /**
         * Calculating duration left for free mint to end.
         */
        const freeDurationLeft =
          startTime.value + freeMintDuration.value - currentTime; // In seconds
        console.log(currentTime, startTime.value, freeMintDuration.value);
        console.log("FREE DURATION LEFT", freeDurationLeft);
        if (freeDurationLeft > 0) {
          freeMintRoundEndDuration.value = freeDurationLeft; // converting in minutes for countdown timer
          isFreeMintActive.value = true;
        } else {
          freeMintRoundEndDuration.value = 0;
          isFreeMintActive.value = false;
        }

        const allowDurationLeft =
          startTime.value +
          freeMintDuration.value +
          allowListDuration.value -
          currentTime;

        if (allowDurationLeft > 0) {
          allowListRoundEndDuration.value = allowDurationLeft;
          isAllowListActive.value = true;
        } else {
          allowListRoundEndDuration.value = 0;
          isAllowListActive.value = false;
        }
        console.log("ALLOW DURATION LEFT", allowDurationLeft);
      }
    };

    const mintPublic = async () => {
      isMinting.value = true;
      try {
        if (publicListMintQuantity.value > 0) {
          let tx = await DCGHeroesRedeemToken.value.publicMint(
            ethers.utils.formatUnits(publicListMintQuantity.value, 0)
          );
          const receipt = await tx.wait();
          if (receipt.status === 1) {
            console.log(receipt);
            console.log("Minted successfully!");
            showRewards.value = true;
            playTrumpetSound.play();
            rewardType.value = "public";
            mintedQuantity.value = publicListMintQuantity.value;
            await refreshData();
          }
        } else {
          store.commit("setNotification", "You need to mint minimum 1");
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isMinting.value = false;
    };

    const mintAllowList = async () => {
      isMinting.value = true;
      try {
        if (allowListMintQuantity.value > 0) {
          console.log(allowListMintQuantity.value);
          let tx = await DCGHeroesRedeemToken.value.allowListMint(
            ethers.utils.formatUnits(allowListMintQuantity.value, 0)
          );
          const receipt = await tx.wait();
          if (receipt.status === 1) {
            console.log(receipt);
            console.log("Minted successfully!");
            showRewards.value = true;
            playTrumpetSound.play();
            rewardType.value = "allow";
            mintedQuantity.value = allowListMintQuantity.value;
            await refreshData();
          }
        } else {
          store.commit("setNotification", "You need to mint minimum 1");
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isMinting.value = false;
    };

    const freeMint = async () => {
      isMinting.value = true;
      try {
        if (availableFreeMints.value > 0) {
          let tx = await DCGHeroesRedeemToken.value.freeMint(
            ethers.utils.formatUnits(availableFreeMints.value, 0)
          );
          const receipt = await tx.wait();
          if (receipt.status === 1) {
            console.log(receipt);
            showRewards.value = true;
            playTrumpetSound.play();
            rewardType.value = "free";
            mintedQuantity.value = availableFreeMints.value;
            await refreshData();
          }
        } else {
          store.commit("setNotification", "No Free Mints Available");
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isMinting.value = false;
    };

    /**
     * Initialising contract instances when signer is available
     */
    const unWatchSessionActive = store.watch(
      () => store.state.isSessionActive,
      async (isSessionActive) => {
        if (isSessionActive) {
          await initialiseContractsAndGetData();
          subscribeToMint();
        }
      }
    );

    /**
     * Method to verify of DCAR is pre-approved
     */
    const checkIsDCAUApproved = async () => {
      let allowanceDCAUMint = await DCAUContract.value.allowance(
        address.value,
        DCGHeroesRedeemToken.value.address
      );
      allowanceDCAUMint = ethers.utils.formatEther(allowanceDCAUMint);
      currentAllowance.value = allowanceDCAUMint;
      console.log("allowance", allowanceDCAUMint);
      if (
        parseFloat(allowanceDCAUMint) >= parseFloat(dcauCost.value) && // The market data returns dcar price also as priceindcau. TODO need to fix this later
        parseFloat(allowanceDCAUMint) > 0.0
      ) {
        isDCAUApproved.value = true;
        // store.commit("marketplace/setisDCAUApproved", false);
      } else {
        isDCAUApproved.value = false;
        // store.commit("marketplace/setisDCAUApproved", true);
      }
    };

    /**
     * Checking if allowance is there or not when changing value.
     */
    const checkAllowanceAgain = async () => {
      if (currentAllowance.value < dcauCost.value) {
        await checkIsDCAUApproved();
      } else {
        isDCAUApproved.value = true;
      }
    };

    const avvyNameForAddress = (address) => {
      store.commit("avvynames/getOrSetAvvyName", address);
      return store.state.avvynames.addressToNames[address];
    };

    /**
     * Subscribing to the Burn Event fired from DCARBattleContract
     * event Burn(address indexed account, uint256 amount, uint256 round);
     */
    const subscribeToMint = () => {
      try {
        const mintContract = getDCGHeroesRedeemTokenContract(signer.value);
        mintContract.on("FreeMint", async (roundId, to, quantity) => {
          console.log("Free Mint", roundId, to, quantity);
          await refreshData();
        });
        mintContract.on("AllowListMint", async (roundId, to, quantity) => {
          console.log("AllowListMint", roundId, to, quantity);
          await refreshData();
        });
        mintContract.on("PublicMint", async (roundId, to, quantity) => {
          console.log("Public Mint", roundId, to, quantity);
          await refreshData();
        });
        console.log("Subscribing to DCAR Burn Event");
      } catch (error) {
        console.log(error);
      }
    };

    /**
     * Method to return decimal value of big number
     * @param {*} amountInBigNumber
     */
    const amountBurnedInDecimal = (amountInBigNumber) => {
      return ethers.utils.formatEther(amountInBigNumber);
    };

    /**
     * Method to approve DCAR for the DCARBattleBurn contract
     */
    const approveDCAU = async () => {
      isApprovingDCAU.value = true;
      try {
        let txApprove = await DCAUContract.value.approve(
          DCGHeroesRedeemToken.value.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await txApprove.wait();
        if (receipt.status === 1) {
          const allowanceDCAUMint = ethers.utils.formatEther(
            receipt.events[0].args.value
          );
          console.log("Allownace DCAU Battle", allowanceDCAUMint);
          isDCAUApproved.value =
            parseFloat(allowanceDCAUMint) >= parseFloat(dcauCost.value);
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isApprovingDCAU.value = false;
    };

    // Helper method to delay stuff
    // const delay = async (time) => new Promise((res) => setTimeout(res, time));

    /**
     * Burning DCAR using the dcar battle contract
     */
    // const burnDCAR = async () => {
    //   isMinting.value = true;
    //   if (dcauCost.value > 0) {
    //     try {
    //       // Executing Heal Transaction
    //       const tx = await DCARBurnBattleContract.value.burnTokens(
    //         ethers.utils.parseEther(`${dcauCost.value}`)
    //       );
    //       store.commit("setTurnHappening", true);
    //       playFireSound.play();
    //       const receipt = await tx.wait();
    //       // Only process Heal if the transaction was successful!
    //       if (receipt.status === 1) {
    //         await fetchLeaderboard(DCARBurnBattleContract.value); // updating leaderboard
    //         await getDCAUBalance(); // updating token balances
    //       }
    //       await delay(2000);
    //       playFireSound.stop();
    //       store.commit("setTurnHappening", false);
    //       playTrumpetSound.play();
    //       store.commit(
    //         "setNotification",
    //         `Successfully burned ${dcauCost.value} DCAR!`
    //       );
    //       await updateDCARBurnedByYou();
    //       await updateTotalBurned();
    //     } catch (error) {
    //       console.log(error);
    //       store.commit("setNotification", parseError(error));
    //       store.commit("setTurnHappening", false);
    //     }
    //   } else {
    //     dcauCost.value = 1;
    //     store.commit(
    //       "setNotification",
    //       "Please enter a DCAR value more than 0"
    //     );
    //     return;
    //   }
    //   isMinting.value = false;
    // };
    const goToGame = () => {
      router.push("/");
    };
    const refresh = () => {
      window.location.reload();
    };

    const totalProgress = computed(() => {
      return (quantityMinted.value / totalQuantity.value) * 100;
    });

    const quantityMinted = computed(() => {
      return totalQuantity.value - quantityLeft.value;
    });

    const isSessionActive = computed(() => {
      return store.state.isSessionActive;
    });

    const isTurnHappening = computed(() => {
      return store.state.isTurnHappening;
    });

    return {
      // data
      isLoading,
      isLoadingInitialData,
      isLoggedIn,
      dcauBalance,
      dcauCost,
      isFetchingDcarBalance,
      isDCAUApproved,
      isApprovingDCAU,
      zeroAddress,
      address,
      currentRound,
      hasStarted,
      freeMintRoundEndDuration,
      allowListRoundEndDuration,
      quantityLeft,
      totalQuantity,
      freeMintDuration,
      allowListDuration,
      startTime,
      maxPerWallet,
      isFreeMintActive,
      isAllowListActive,
      availableFreeMints,
      availableAllowMints,
      durationToStart,
      allowListMintQuantity,
      publicListMintQuantity,
      quantityMinted,
      isMinting,
      showRewards,
      rewardType,
      mintedQuantity,
      yourTokenCount,
      // address,
      // method
      checkAllowanceAgain,
      refresh,
      goToGame,
      approveDCAU,
      amountBurnedInDecimal,
      avvyNameForAddress,
      loadPage,
      mintAllowList,
      freeMint,
      mintPublic,
      endFreeMint,
      endAllowMint,
      startRound,
      closeReward,
      // computed
      isSessionActive,
      isTurnHappening,
      totalProgress,
    };
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;600;700&display=swap");

video.fire {
  opacity: 0;
  /* transform: translateY(100%); */
  transition: all 0.5s ease;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  position: absolute;
  left: 0;
  display: none;
  @media only screen and (max-width: 576px) {
    bottom: 0;
    height: 100vh;
    width: auto;
    object-fit: cover;
    position: fixed;
  }
}
.dark-mode {
  .bg {
    /* background: #2c1c10 url("../../assets/burning-bg-night.png"); */
    background-color: #c2c2c2;
    background-position: top;
    background-attachment: fixed;
    background-size: cover;
  }
}
.bg {
  background: #172a2e url("../../assets/viking-mint-bg.jpg");
  // background-color: #000;
  background-position: top;
  background-attachment: fixed;
  background-size: cover;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 1s ease;
  @media only screen and (max-width: 576px) {
    width: 100%;
    height: 100%;
    position: fixed;
  }
}
.animate-fire {
  background: transparent;

  .bg {
    transform: scale(1.5) translate(60px, 100px);
  }
  .white-box {
    opacity: 0;
  }

  video.fire {
    opacity: 0.7;
    display: block;
    /* transform: translateY(0); */
  }
}
.burning-dcar h3 {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-weight: bold;
  position: relative;
  top: 50px;
  .loading {
    background: none;
    width: 25px !important;
    height: 25px;
    margin-right: 20px;
  }
  &:after {
    background: url("../../assets/ui/dcar.png") no-repeat left center;
    background-size: contain;
    content: "";
    display: inline-block;
    width: 46px;
    height: 46px;
    line-height: 0;
    margin-top: -13px;
    padding: 0;
    top: 13px;
    position: relative;
    margin-right: 4px;
  }
}

.balances {
  div {
    display: block;
    align-items: center;
    justify-items: flex-start;
    font-size: 1.4rem;
    margin: 11px 0;
    font-family: "Lato", sans-serif;
    color: #000;
    &:before {
      background-size: contain;
      content: "";
      display: inline-block;
      width: 46px;
      height: 46px;
      line-height: 0;
      margin-top: -13px;
      padding: 0;
      top: 13px;
      position: relative;
      margin-right: 4px;
    }
    p {
      margin: 0;
      span {
        font-size: 0.7rem;
        display: block;
        margin: 2px 0 0;
        color: #ffffff85;
      }
    }
  }
  .dcau {
    &:before {
      background: url("../../assets/ui/dcau.png") no-repeat left center;
    }
  }
  .dcar {
    &:before {
      background: url("../../assets/ui/dcar.png") no-repeat left center;
    }
  }
}
.reward {
  background-color: #000000e5;
  backdrop-filter: blur(2px);
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  .data {
    &:before {
      content: "";
      background: url("../../assets/mint-token.png") no-repeat center center;
      width: 300px;
      height: 300px;
      background-size: cover;
      display: block;
      margin: 0 auto;
    }
    h2 {
      margin-bottom: 10px;
    }
    h3 {
      color: #fff;
      font-size: 1.2rem;
      margin-top: 0;
    }
    top: 55%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.content {
  padding-top: 200px;
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  /* &.dark-mode {
      &:before {
        display: block;
        content: "";
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #00000069;
      }
    } */
  .grid-overall {
    width: 1200px;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    &.two-col {
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 576px) {
      width: 100%;
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    .top-burner {
      h4:before {
        content: "";
        width: 25px;
        height: 25px;
        background-image: url("../../assets/crown.svg");
        background-size: contain;
        display: inline-block;
        margin-right: 7px;
        top: 6px;
        position: relative;
        margin-top: -10px;
      }
    }
    .white-box {
      width: 100%;

      .leaderboard-entry {
        font-size: 1.1rem;
      }
      .balances div {
        font-size: 1.2rem;
      }
    }
  }

  .black-box {
    width: 100%;
    margin: 0 auto 1.5rem;
    display: block;
    background: #00000092;
    backdrop-filter: blur(1.3px);
    border-radius: 2px;
    box-shadow: 0 0 2px 2px #00000045;
    transition: all 0.5s ease;
    .available-free-mints {
      color: #fff;
      font-size: 2rem;
      font-weight: bold;
    }

    .grid {
      .col {
        background-color: #ffffff3d;
        color: #fff;
        p {
          color: rgba(255, 255, 255, 0.434);
        }
      }
      h5 {
        color: #fff;
      }
    }
    .leaderboard-entry {
      color: #fff;
    }
    .balances {
      div {
        color: #fff;
      }
    }
    &.rewards {
      width: 100%;
      margin-bottom: 0;
      h1 {
        color: #000;
        @media only screen and (max-width: 576px) {
          font-size: 1.2rem;
        }
      }
      img {
        max-width: 90%;
      }
    }
    &.details {
      width: calc(100% - 2rem);
      padding: 1rem;
      font-size: 3rem;
      font-family: "Cinzel", serif;
      @media only screen and (max-width: 576px) {
        font-size: 1.5rem;
      }
    }
    h4,
    h1 {
      display: block;
      background: rgb(179, 81, 38);
      margin: 0;
      background: linear-gradient(
        90deg,
        rgba(179, 81, 38, 1) 0%,
        rgba(232, 206, 117, 1) 50%,
        rgba(179, 81, 38, 1) 100%
      );
      width: calc(100% - 20px);
      padding: 10px;
      color: #4a1d0e;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 10px;
    padding: 10px;
    &.two {
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }

    h5 {
      margin: 0 0 10px;
      color: #000;
      font-family: "Lato", sans-serif;
    }
    .col {
      background-color: #0000003d;
      padding: 1rem;
      box-shadow: 0 0 2px 1px #0000009c;

      .mint-quantity {
        font-size: 1.2rem;
        width: 50%;
        margin: 1px 0;
        padding: 3px;
        border: none;
        border-radius: 5px;
        box-shadow: 0 0 2px 2px #0000003b;
        text-align: center;
      }
      p {
        color: #000000c3;
        font-size: 0.7rem;
        font-family: Lato;
        margin: 6px 0 0;
        position: relative;
      }
    }
  }
  .burn-dcar-button {
    padding: 1rem;
  }
  .white-box {
    width: 100%;
    margin: 0 auto 1.5rem;
    display: block;
    background: #ffffff76;
    backdrop-filter: blur(1.3px);
    border-radius: 2px;
    box-shadow: 0 0 2px 2px #00000045;
    transition: all 0.5s ease;
    @media only screen and (max-width: 576px) {
      width: 100%;
    }
    &.rewards {
      width: 100%;
      margin-bottom: 0;
      h1 {
        color: #000;
        @media only screen and (max-width: 576px) {
          font-size: 1.2rem;
        }
      }
      img {
        max-width: 90%;
      }
    }
    .grid {
      .col {
        background-color: #0000003d;
      }
      h5 {
        color: #000;
      }
    }
    h4,
    h1 {
      display: block;
      background: rgb(179, 81, 38);
      margin: 0;
      background: linear-gradient(
        90deg,
        rgba(179, 81, 38, 1) 0%,
        rgba(232, 206, 117, 1) 50%,
        rgba(179, 81, 38, 1) 100%
      );
      width: calc(100% - 20px);
      padding: 10px;
      color: #4a1d0e;
    }
  }
  .leaderboard-entry {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 1.3rem;
    padding: 5px 0;
    border-bottom: 1px solid #0000004d;
    color: #000;
    align-items: center;
    font-family: "Lato", sans-serif;
    &:nth-child(odd) {
      background-color: #00000017;
    }
    .user {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 5px;
    }
    .balances {
      div:before {
        width: 25px;
        height: 25px;
        line-height: 0;
        margin-top: -5px;
        padding: 0;
        top: 5px;
        background-size: cover;
      }
    }
  }

  .leaderboard-entry.single-column {
    grid-template-columns: 1fr;
  }
  .leaderboard-list {
    .leaderboard-entry {
      grid-template-columns: 50px 1fr 1fr;
      justify-items: center;
      .rank {
        background: #000000a8;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        color: #fff;
        font-size: 1rem;
        display: grid;
        align-items: center;
      }
    }
  }
}

.collapse,
.not-collapsed {
  h1 {
    position: relative;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    &:before {
      font-size: 1.2rem;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.not-collapsed {
  h1 {
    &:before {
      content: "▲";
    }
  }
}
.collapse {
  h1:before {
    content: "▼";
  }
  div {
    display: none;
  }
  .fade-in {
    animation: none;
  }
}
.intro {
  div {
    padding: 1.5rem;
    text-align: center;
  }
  p {
    font-family: Lato;
    font-size: 1.5rem;
  }
}
p.allowed-mints {
  font-size: 1.5rem !important;
  margin: 15px 0 !important;
}
/* .fade-in {
    opacity: 0;
    animation: fade-in 1s forwards;
    animation-delay: 3.5s;
  } */
/* .fade-in-2 {
    opacity: 0;
    animation: fade-in 1s forwards;
    animation-delay: 4s;
  } */

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.typing {
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3.5s steps(40, end);
}
.show-scale {
  animation: scale 2s ease-in-out forwards;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  50% {
    border-color: transparent;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.video-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 0;
  padding-bottom: 56.25%; /* 315 divided by 560 equals the aspect ratio of 0.5625 or 56.25% */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.progress-container {
  padding: 1rem;
  width: calc(100% - 2rem) !important;
  p.current {
    font-size: 1.9rem;
    margin: 0;
    color: #fff;
    font-weight: bold;
  }
  .progress {
    width: 100%;
    background: #0000009e;
    min-height: 30px;
    margin: 20px auto;
    position: relative;
    p.mark {
      position: absolute;
      bottom: -22px;
      margin: 0;
      font-size: 1.5rem;
      color: #ffffff;
      &.start {
        left: 0;
      }
      &.end {
        right: 0;
      }
      &.current {
        top: -30px;
      }
    }
    span {
      background: rgb(131, 74, 5);
      background-image: linear-gradient(
        to right,
        #005ac3,
        #035ecd,
        #0763d7,
        #0d67e1,
        #126ceb
      );
      box-shadow: inset 0 0 12px 4px rgb(255 245 123 / 18%);
      display: block;
      height: 100%;
      height: 30px;
      position: relative;
      &:after {
        background-image: url("../../assets/fire.gif");

        content: "";
        width: 25px;
        display: block;
        height: 47px;
        top: -14px;
        position: absolute;
        background-size: cover;
        padding: 0;
        background-position: center center;
        right: -12px;
      }
    }
  }
}
.timer {
  padding: 2rem 0;
  h2 {
    font-family: "Lato", sans-serif;
  }
}
</style>
